import { API_URL } from "../../constants";
import { axiosInstance } from "../_base/axios.instance";

export async function addContact({ username, email, message }){
    
    const response = await axiosInstance.post(`${API_URL}/contacts/add`,
        {
            name: username,
            email,
            message
        }
    );
    return response;
}