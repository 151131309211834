import { Link } from "react-router-dom";
import "./contactCard.style.css";
import { useDataContext } from "../../../hook";

export function ContactCard({ contact }) {

  const { setSharedData } = useDataContext();

  return (
    <>
      <Link to={`/mensagem/${contact.id}`} onClick={() => setSharedData(contact)} key={contact} className="contactCard_link">
        <h3>{contact.name}</h3>
        <p>ID: {contact.id}</p>
        <p>
            Data:{" "}
            {new Date(contact.createdAt).toLocaleString("pt-BR", {
              dateStyle: "short",
              timeStyle: "short",
            })}
          </p>
      </Link>
    </>
  );
}
