import { useEffect } from "react";
import { ContactCard, Header } from "../../Components";
import "./messages.style.css";
import { validateToken } from "../../../constants/functions";
import { useNavigate } from "react-router-dom";
import { useNotify, usePage } from "../../../hook";
import useGlobalUser from "../../../context/user.context";
import { getAllContact } from "../../../api/contact/getAllContact.api";
import { useState } from "react";

export function Messages() {
  const { setUser } = useGlobalUser();
  const navigate = useNavigate();
  const { notify } = useNotify();
  const { page, handlePreviousPage, handleNextPage } = usePage();
  const [contacts, setContacts] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);

  async function fetchContacts() {
    try {
      const response = await getAllContact(page);
      setContacts(response.data.contacts);
      setHasNextPage(response.data.hasNextPage);
    } catch (error) {
      notify(error.response.data.message);
    }
  }

  useEffect(() => {
    validateToken(navigate, setUser, notify);
    fetchContacts();
  }, [page]);
  
  return (
    <>
      <Header />
      <main className="messages_main">
        <section className="card_section--list">
          {contacts.map((contact) => (
            <ContactCard key={contact.id} contact={contact} />
          ))}
        </section>
        <div className="pagination">
          <button
            className="pagination-button"
            onClick={handlePreviousPage}
            disabled={page === 0}
          >
            &#8249;
          </button>
          <span className="pagination-page">Página {page+1}</span>
          <button
            className="pagination-button"
            onClick={handleNextPage}
            disabled={hasNextPage === false}
          >
            &#8250;
          </button>
        </div>
      </main>
    </>
  );
}
