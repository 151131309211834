import { createContext, useState } from "react";

export const DataContext = createContext(null);
export const DataProvider = ({ children }) => {
    const [ sharedData, setSharedData ] = useState(null);
    return(
        <DataContext.Provider value={{sharedData, setSharedData}}>
            {children}
        </DataContext.Provider>
    );
};
