import { useNavigate } from "react-router-dom";
import { useDataContext } from "../../../hook";
import { Header } from "../../Components/index";
import "./message.style.css";
import { useEffect } from "react";

export function Message() {
  const { sharedData } = useDataContext();
  const navigate = useNavigate();

  useEffect(()=>{
    sharedData === null ? navigate("/mensagens") : navigate("");
  }, [])

  return (
    <>
      <Header />
      <main className="message_main">
        <div className="message_card">
          <h2 className="message_name">{sharedData?.name}</h2>
          <p className="message_email">{sharedData?.email}</p>
          <p className="message_date">
            Enviado em: {new Date(sharedData?.createdAt).toLocaleString()}
          </p>
          <div className="message_content">
            <p>{sharedData?.message}</p>
          </div>
        </div>
      </main>
    </>
  );
}
