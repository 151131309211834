import "./card.style.css";
import { useHandleHover } from "../../../hook/index.jsx";
import { ImageShow } from "../../../constants/functions/index.js";
import { Link } from "react-router-dom";
import { React ,useRef, useEffect, useState } from "react";

export function Card({ post }) {

    const cardRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const { hovered, handleMouseEnter, handleMouseLeave } = useHandleHover();

    useEffect(() => {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
              observer.unobserve(entry.target); // Para evitar reaplicação da animação
            }
          },
          {
            root: null, // Viewport padrão
            rootMargin: "0px",
            threshold: 0.1, // Dispara quando 10% do elemento está visível
          }
        );
    
        if (cardRef.current) {
          observer.observe(cardRef.current);
        }
    
        return () => {
          if (cardRef.current) {
            observer.unobserve(cardRef.current); // Cleanup
          }
        };
      }, []);

    return (
        <Link
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave} 
        ref={cardRef}
        className={`card_link ${isVisible ? "show" : ""}`}
        to={`/post/${post.id}`}>
            <strong className={hovered ? "card_strong show" : "card_strong"}>{post.title}</strong>
            <img className="card_img " src={ImageShow(post.mainImage)} alt="imagem do projeto" loading="lazy"/>
        </Link>
    );
};