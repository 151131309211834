import { API_URL } from "../../constants";
import { axiosInstance } from "../_base/axios.instance";

export async function getAllContact(page){
    const PAGE_SIZE = 5;
    const response = await axiosInstance.get(`${API_URL}/contacts/getAll?page=${page}&size=${PAGE_SIZE}`,
        {}
    );
    return response;
};
