import "./contact.style.css";
import { Footer, Header } from "../../Components/index";
import whatsapp from "../../../assets/ico/whatsapp.svg";
import email from "../../../assets/ico/email.svg";
import insta from "../../../assets/ico/instagram-ico.svg";
import { useFormInputs, useNotify } from "../../../hook";
import { addContact } from "../../../api/contact/addContact.api";
import { ToastContainer } from "react-toastify";

export function Contact() {
  const { formInputs, handleChange } = useFormInputs({
    username: "",
    email: "",
    message: "",
  });
  const { notify } = useNotify();
  async function handleSubmit(event) {
    event.preventDefault();
    const { username, email, message } = formInputs;
    try {
      const response = await addContact({
        username: username,
        email: email,
        message: message,
      });
      notify(response.data.message);
    } catch (error) {
      notify(error.response.data.message);
    }
  }

  return (
    <>
      <Header selected="Contact" />
      <main className="contact_main">
        <h1 className="contact_main--title">contato</h1>
        <section className="contact_section">
          <form className="contact_email--div" onSubmit={handleSubmit}>
            <strong className="contact_email--div_title">Contate-nos</strong>
            <input
              className="contact_email--input"
              type="text"
              placeholder="nome"
              name="username"
              onChange={handleChange}
            />
            <input
              className="contact_email--input"
              type="email"
              placeholder="e-mail"
              name="email"
              onChange={handleChange}
            />
            <textarea
              className="contact_email--input textarea"
              placeholder="sua mensagem"
              name="message"
              onChange={handleChange}
            />
            <div className="contact_email--button_div">
              <button className="contact_email--button">Enviar</button>
            </div>
          </form>
          <div className="contact_info--div">
            <div className="contact_info--title_div">
              <strong className="contact-info-title">Fale conosco</strong>
              <span className="contact-info-text">
                Vamos falar sobre seu projeto? Entre em contato conosco.
              </span>
            </div>
            <div className="contact_info_manifest--div">
              <div className="contact_info--settings_div">
                <img src={whatsapp} alt="Ícone do whatsapp" />
                <span className="contact-info-text">(81) 99351-3178</span>
              </div>
              <div className="contact_info--settings_div">
                <img src={email} alt="Ícone do e-mail" />
                <span className="contact-info-text">
                  comercial@basilioarquitetos.com
                </span>
              </div>
              <a
                href="https://www.instagram.com/basilioarquitetos/"
                className="contact_info--settings_div"
                target="_blank"
                rel="noreferrer"
              >
                <img src={insta} alt="Ícone do instagram" />
                <span className="contact-info-text">instagram</span>
              </a>
            </div>
          </div>
        </section>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          style={{ textAlign: "justify" }}
        />
      </main>
      <Footer />
    </>
  );
}
